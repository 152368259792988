import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import HomePageCards from "../HomePage/HomePageCards";
import styles from "../../pages/HomePage/HomePage.module.scss";
import Container from "../../components/Container/Container";
import AccountImage from "../../assets/images/account.png";
import WelletImage from "../../assets/images/wallet.png";
import { useAuth } from "../../context/AuthProvider";

const HomePage = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.shouldOpenModal) {
      setIsAuthModalOpen(true);
      navigate("/", { state: { shouldOpenModal: false } });
    }
  }, [location, navigate]);

  const handleOpenLink = useCallback((event, link) => {
    event.stopPropagation();
    if (isAuthenticated && link) {
      navigate(link);
    } else {
      setIsAuthModalOpen(true);
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container onOpenModal={setIsAuthModalOpen} isModalOpan={isAuthModalOpen}>
      <div className={clsx(styles.imagesContainer)}>
        <HomePageCards
          src={AccountImage}
          title="Личный кабинет"
          onClick={(event) => handleOpenLink(event, "/profile")}
        />
        <HomePageCards
          src={WelletImage}
          title="Кошелек"
          onClick={(event) => handleOpenLink(event, "/wallet")}
        />
      </div>
    </Container>
  );
};

export default HomePage;
