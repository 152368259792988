import React from "react";
import styles from "./Input.module.scss";
import Typography from "../Typography/Typography";
import RuFlag from "../../assets/images/ru-flag.png";

const Input = ({
  id,
  label,
  placeholder,
  className,
  error,
  enableFlag,
  ...other
}) => {
  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor={id}>
          <Typography color="grey">{label}</Typography>
        </label>
      )}

      <input
        id={id}
        type="text"
        placeholder={placeholder}
        className={`${styles.input} ${className}`}
        {...other}
      />

      {enableFlag && <img src={RuFlag} alt="" className={styles.flag} />}

      {error && (
        <Typography className={styles.error} weight={500}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default Input;
