import clsx from "clsx";
import styles from "../../components/Typography/Typography.module.scss";

const Typography = (props) => {
  const {
    as = "p",
    children,
    color = "white",
    weight = 400,
    size = 16,
    align,
    className,
    href,
    ...rest
  } = props;

  const Componennt = as ?? "p";

  return (
    <Componennt
      href={href}
      className={clsx(
        className,
        styles.typography,
        color && styles[color],
        weight && styles[`weight-${weight}`],
        size && styles[`size-${size}`],
        align && styles[`align-${align}`]
      )}
      {...rest}
    >
      {children}
    </Componennt>
  );
};

export default Typography;
