import clsx from "clsx";
import styles from "../../components/Buttons/Button.module.scss";
import Typography from "../../components/Typography/Typography";

 const RoundedButton = (props) => {
  const { children, className, long, typographyColor, ...rest } = props;

  return (
    <button
      className={clsx(styles.button, className, long && styles.long)}
      {...rest} 
    >
      <Typography size={18} align="center" color={typographyColor ?? "white"}>
        {children}
      </Typography>
    </button>
  );
};

export default RoundedButton 
