import React, { useState, useEffect } from "react";
import Typography from "../../components/Typography/Typography";
import styles from "../../components/Header/Header.module.scss";
import RoundedButton from "../../components/Buttons/RoundedButton";
import Input from "../../components/Input/Input";
import Modal from "../../components/Modal/Modal";
import { ReactComponent as PhoneSvg } from "../../assets/images/icons/phone.svg";
import { ReactComponent as CoinSvg } from "../../assets/images/icons/coin.svg";
import CircledInput from "../../components/Input/CircledInput";
import { useAuth } from "../../context/AuthProvider";
import { useMessageFields } from "../../hooks/useMessageFields";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../api/domains/auth.api";
import logoSvg from "../../assets/images/logo.svg";
import BuregerMenu from "../../components/Header/BurgerMenu";
import { useScreenSize } from "../../hooks/useScreenSize";
import { getPadTime } from "./getPadTime";

const Header = (props) => {
  const { onOpenModal, isModalOpan } = props;
  const navigate = useNavigate();

  const [openAuthFields, setOpenAuthFields] = useState(false);
  const [number, setNumber] = useState("");
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");

  const [timeLeft, setTimeLeft] = useState(30);
  const [isCounting, setIsCounting] = useState(false);

  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(true);

  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(timeLeft - minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCounting && timeLeft > 0) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        setIsCounting(false);
        setShow(true);
        setShowInfo(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isCounting, timeLeft]);

  const handleStart = () => {
    if (timeLeft === 0) setTimeLeft(30);
    setIsCounting(true);
  };

  const [error, setError] = useState({
    numberError: "",
    nameError: "",
    userNameError: "",
  });

  const { handleChange, messageCode } = useMessageFields();

  const { isAuthenticated, setIsAuthenticated, setCoins, coins } = useAuth();

  const messageCodeValue = Object.values(messageCode).join("");

  const fetchCoins = async () => {
    try {
      const { coin } = await AuthApi.getUserCoins();
      setCoins(coin);
    } catch (error) {
      console.error(error);
    }
  };

  const width = useScreenSize();

  useEffect(() => {
    if (localStorage.getItem("rupor:token")) {
      fetchCoins();
    }
  });

  const handleCloseModal = (register) => {
    setError({});
    onOpenModal(false);
    setIsMessageSend(false);
    setOpenAuthFields(false);
  };

  const isPhoneNumberCorrect = (number) =>
    /^\+\d{1} \d{3} \d{3} \d{2} \d{2}$/.test(number);

  const handleSendMessage = async () => {
    try {
      await AuthApi.sendConfirmCode(number);
      setIsMessageSend(true);
      handleStart();
      setTimeout(() => {
        setShow(!show);
      }, 31000);
      setTimeout(() => {
        setShowInfo(!showInfo);
      }, 31000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmMessage = async () => {
    try {
      const data = await AuthApi.confirmCode(number, messageCodeValue);
      if (data.status === 200) {
        localStorage.setItem("rupor:token", `${data.auth_token.access}`);
        setIsAuthenticated(true);
        localStorage.setItem("rupor:isAuth", true);
      }
      if (!data.first_name) {
        setIsMessageSend(false);
        setOpenAuthFields(true);
      } else {
        handleCloseModal(true);
      }
      fetchCoins();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveUserName = async () => {
    try {
      await AuthApi.saveUserName(name, userName);
      handleCloseModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignOut = async () => {
    const logout = await AuthApi.userLogout();
    if (logout.status === 200) {
      localStorage.removeItem("rupor:isAuth", true);
      localStorage.removeItem("rupor:token");
      setIsAuthenticated(false);
      navigate("/");
    }
  };

  const handleInputChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber);
    setNumber(formattedPhoneNumber);
  };

  const formatPhoneNumber = (inputPhoneNumber) => {
    const cleaned = ("" + inputPhoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return (
        "+" +
        match[1] +
        " " +
        match[2] +
        " " +
        match[3] +
        " " +
        match[4] +
        " " +
        match[5]
      );
    }
    return inputPhoneNumber;
  };

  const isFormValid = () => {
    return isPhoneNumberCorrect(number);
  };

  const handleSendMessageReset = async () => {
    try {
      await AuthApi.sendConfirmCode(number);
      setIsMessageSend(true);
      handleStart();
      setShow(!show);
      setShowInfo(!showInfo);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      {width > 768 && (
        <>
          <a href="/">
            <img src={logoSvg} alt="logo" />
          </a>
          <div className={styles.balance}>
            {isAuthenticated && coins !== null && coins !== undefined && (
              <div className={styles.column}>
                <Typography color="grey" className={styles.caption}>
                  Мой баланс
                </Typography>
                <Typography size={18} weight={700} className={styles.amount}>
                  {coins
                    .toString()
                    .split(/(?=(?:\d{3})+(?!\d))/g)
                    .join(" ")}
                  <CoinSvg />
                </Typography>
              </div>
            )}
            {isAuthenticated ? (
              <RoundedButton className={styles.button} onClick={handleSignOut}>
                Выход
              </RoundedButton>
            ) : (
              <RoundedButton
                className={styles.button}
                onClick={() => onOpenModal(true)}
              >
                Вход
              </RoundedButton>
            )}
          </div>
        </>
      )}
      {width <= 768 && (
        <div className={styles.burgerMenuContainer}>
          <div
            className={clsx(styles.burgerIcon, openBurgerMenu && styles.open)}
            onClick={() => setOpenBurgerMenu((prev) => !prev)}
          ></div>
          <img src={logoSvg} alt="logo" />
          <div>
            {isAuthenticated ? (
              <RoundedButton className={styles.button} onClick={handleSignOut}>
                Выход
              </RoundedButton>
            ) : (
              <RoundedButton
                className={styles.button}
                onClick={() => onOpenModal(true)}
              >
                Вход
              </RoundedButton>
            )}
          </div>
        </div>
      )}
      <BuregerMenu
        isOpen={openBurgerMenu && width <= 768}
        onClose={() => setOpenBurgerMenu(false)}
        coins={coins}
      />

      <Modal isOpen={isModalOpan} onClose={() => handleCloseModal(false)}>
        {!isMessageSend && !openAuthFields && (
          <>
            <Typography weight={700} size={20} className={styles.modalTitle}>
              Вход
            </Typography>
            <Input
              format="+# ### ### ## ##"
              className={styles.paddingLeft}
              type="tel"
              label="Введите номер телефона"
              onChange={handleInputChange}
              value={number.startsWith("+7 ") ? number : "+7 " + number}
              placeholder="+7 "
              error={isPhoneNumberCorrect(number) ? "" : error.numberError}
              enableFlag={true}
              onBlur={(event) => {
                if (!isPhoneNumberCorrect(event.target.value)) {
                  setError({
                    ...error,
                    numberError: "Заполните номер телефона",
                  });
                }
              }}
            />
            <RoundedButton
              type="button"
              long
              className={`${styles.modalButton} ${
                isFormValid() ? styles.active : styles.disabled
              }`}
              disabled={!isFormValid}
              onClick={handleSendMessage}
              typographyColor={isFormValid ? "white" : "grey"}
            >
              Продолжить
            </RoundedButton>
          </>
        )}

        {isMessageSend && !openAuthFields && (
          <>
            <div className={styles.callPhoneContainer}>
              <PhoneSvg />
              <div style={{ marginLeft: "10px" }}>
                <Typography size={17} weight={600} className={styles.callPhone}>
                  +7 (XXX) XXX -{" "}
                  <Typography as="span" size={17} weight={600}>
                    XX - XX
                  </Typography>
                </Typography>
                <Typography size={17} weight={400}>
                  Входящий{" "}
                  <Typography as="span" size={17} weight={600}>
                    звонок или смс
                  </Typography>
                </Typography>
              </div>
            </div>
            <Typography className={styles.hint} align="center">
              Введите последние 4 цифры номера или код из смс
            </Typography>
            <div className={styles.circledInputContainer}>
              <CircledInput
                type="tel"
                maxLength="1"
                name="message-1"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-2"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-3"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-4"
                onChange={handleChange}
              />
            </div>

            <div className={styles.timer}>
              {showInfo && (
                <div className={styles.timerInfo}>
                  <Typography className={styles.white} align="center">
                    Повторный звонок доступен через
                  </Typography>
                  <span className={styles.time}>
                    {minutes}:{seconds}
                  </span>
                </div>
              )}

              {show && (
                <div>
                  <span
                    id="spanRepeat"
                    className={styles.spanButton}
                    onClick={handleSendMessageReset}
                  >
                    Отправить повторно
                  </span>
                </div>
              )}
            </div>

            <RoundedButton
              type="button"
              long
              className={`${styles.modalButton} ${
                messageCodeValue.length >= 4 ? styles.active : styles.disabled
              }`}
              disabled={!(messageCodeValue.length >= 4)}
              onClick={handleConfirmMessage}
              typographyColor={messageCodeValue.length >= 4 ? "white" : "grey"}
            >
              Продолжить
            </RoundedButton>
          </>
        )}

        {openAuthFields && isMessageSend && (
          <>
            <Typography weight={700} size={20} className={styles.modalTitle}>
              Регистрация
            </Typography>
            <Input
              label="Введите имя"
              placeholder="Имя *"
              error={name.length >= 4 ? "" : error.nameError}
              onChange={(event) => setName(event.target.value)}
              onBlur={(event) => {
                if (event.target.value.length < 4) {
                  setError({
                    ...error,
                    nameError:
                      "Поля не может быть пустым минимальная значения 4",
                  }); 
                }
              }}
            />
            <Input
              label="Введите никнейм"
              placeholder="Никнейм *" 
              error={userName.length >= 4 ? "" : error.userNameError}
              onChange={(event) => setUserName(event.target.value)}
              onBlur={(event) => {
                if (event.target.value.length < 4) {
                  setError({
                    ...error,
                    userNameError:
                      "Поля не может быть пустым минимальная значения 4",
                  });
                }
              }}
            />
            <RoundedButton
              type="button"
              long
              className={styles.modalButton}
              disabled={!(name.length >= 4 && userName.length >= 4)}
              onClick={handleSaveUserName}
            >
              Продолжить
            </RoundedButton>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Header;
