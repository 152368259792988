import styles from "../../components/Modal/Modal.module.scss";
import logoSvg from "../../assets/images/logo.svg";

import { ReactComponent as CloseModal } from "../../assets/images/icons/close-modal.svg";

const Modal = (props) => {
  const { children, onClose, isOpen } = props;

  return (
    <>
      {isOpen ? (
        <div className={styles.container}>
          <div className={styles.modal}>
            <CloseModal className={styles.closeButton} onClick={onClose} />
            <div className={styles.bodyWrapper}>
              <img src={logoSvg} alt="logo" className={styles.logo} />
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
