import clsx from "clsx";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import styles from "./Container.module.scss";
import Typography from "../Typography/Typography";

import { ReactComponent as BackSvg } from "../../assets/images/icons/back.svg";
import { useScreenSize } from "../../hooks/useScreenSize";

const Container = (props) => {
  const {
    children,
    className,
    titleClassName,
    title,
    onOpenModal,
    isModalOpan,
    header = true,
    footer = true,
    isFooterAbsolute = false,
  } = props;
  const width = useScreenSize();

  return (
    <div className={clsx("container", className)}>
      <>
        {header && (
          <Header onOpenModal={onOpenModal} isModalOpan={isModalOpan} />
        )}
        {title?.name && width > 786 && (
          <div className={clsx(styles.titleContainer, titleClassName)}>
            <a className={styles.backIcon} href={title.href}>
              <BackSvg />
            </a>
            <Typography size={36} weight={700} align="center">
              {title.name}
            </Typography>
          </div>
        )}
        {children}
      </>
      {footer && <Footer isAbsolute={isFooterAbsolute} />}
    </div>
  );
};

export default Container;
