const { createContext, useState, useContext } = require("react");

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("rupor:isAuth")
  );
  const [coins, setCoins] = useState("");

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        setCoins,
        coins,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { isAuthenticated, setIsAuthenticated, coins, setCoins } =
    useContext(AuthContext);

  return { isAuthenticated, setIsAuthenticated, coins, setCoins };
};
