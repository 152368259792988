import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import RoundedButton from "../Buttons/RoundedButton";
import Modal from "../Modal/Modal";
import Typography from "../Typography/Typography";

import styles from "../../components/Modal/Modal.module.scss";

const FaqModal = (props) => {
  const { isOpen, onClose } = props;
  const [messageValue, setMessageValue] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Tabs>
        <TabList className={styles.tabs}>
          <Tab selectedClassName={styles.active}>
            <Typography weight={600}>Поддержка</Typography>
          </Tab>
          <Tab selectedClassName={styles.active}>
            <Typography weight={600}>Другое</Typography>
          </Tab>
        </TabList>

        <TabPanel className={styles.tabPanel}>
          <Typography size={20} weight={600} align="left">
            Ваше сообщение
          </Typography>

          <textarea
            className={styles.textarea}
            value={messageValue}
            onBlur={(event) => {
              setMessageValue(event.target.value);
            }}
            onChange={(event) => {
              setMessageValue(event.target.value);
            }}
          />

          <RoundedButton
            type="button"
            long
            className={styles.modalButton}
            disabled={!(messageValue.length >= 10)}
          >
            Отправить
          </RoundedButton>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <Typography size={20} weight={600} align="left">
            Ваше сообщение
          </Typography>

          <textarea
            rows="6"
            className={styles.textarea}
            value={messageValue}
            onChange={(event) => {
              setMessageValue(event.target.value);
            }}
            onBlur={(event) => {
              setMessageValue(event.target.value);
            }}
          />

          <RoundedButton
            type="button"
            long
            className={styles.modalButton}
            disabled={!(messageValue.length >= 10)}
          >
            Отправить
          </RoundedButton>
        </TabPanel>
      </Tabs>
    </Modal>
  );
};

export default FaqModal;
