import { httpClient } from "../../api/http-client"

export const AuthApi = {
  sendConfirmCode: async (number) => {
    const response = await httpClient.post("/users/new_send_code/", {
      phone_number: number,
    });

    return response;
  },

  confirmCode: async (number, code) => {
    const response = await httpClient.post("/users/new_code_verify/", {
      phone_number: number,
      code: code,
    });

    return response;
  },

  saveUserName: async (name, userName, token) => {
    try {
      const response = await httpClient.post("/users/new_set_user_data/", { 
        username: name,
        first_name: userName,
        save: true,
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  },

  getUserInfo: async () => {
    const response = await httpClient.post("/users/public_profile");  

    return response;
  },

  getUserCoins: async () => {
    const response = await httpClient.get("/payments/get_coin/");

    return response;
  },

  userLogout: async () => {
    const response = await httpClient.post("/users/logout/");

    return response;
  },
};
