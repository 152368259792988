import { useState } from "react";
import styles from "./Footer.module.scss";
import Link from "../Link/Link";
import Typography from "../../components/Typography/Typography";
import clsx from "clsx";
import MastercardCheckImage from "../../assets/images/mastercard-check.png";
import MastercardImage from "../../assets/images/mastercard.png";
import MirAcceptImage from "../../assets/images/mir-accpet.png";
import MirImage from "../../assets/images/mir.png";
import VisaSecureImage from "../../assets/images/visa-secure.png";
import VisaImage from "../../assets/images/visa.png";
import FaqModal from "../../components/Modal/FaqModal";
import { useScreenSize } from "../../hooks/useScreenSize";

const Footer = (props) => {
  const { isAbsolute } = props;
  const [faqModal, setFaqModal] = useState(false);
  const width = useScreenSize();

  return (
    <div
      className={clsx(
        "container",
        styles.container,
        isAbsolute && styles.absolute
      )}
    >
      {width > 768 && (
        <div className={styles.links}>
          <Link href={"/about"}>
            <Typography color="grey">О нас</Typography>
          </Link>
          <Link href={"/contacts"}>
            <Typography color="grey">Контакты</Typography>
          </Link>
          <Link
            target="_blank"
            href={
              "https://storage.yandexcloud.net/talkme-prod/media/main/privacy.html"
            } // Исправлено на privacy.html
            download
          >
            <Typography color="grey">Правила использования</Typography>
          </Link>
          <Link onClick={() => setFaqModal(true)}>
            <Typography color="grey">FAQ</Typography>
          </Link>
          <Link href={"http://t.me/talkme_live_bot?start=info"} target="_blank">
            <Typography color="grey">Написать нам</Typography>
          </Link>
        </div>
      )}
      <div className={styles.imageLinksContainer}>
        <div>2023г. Все права защищены</div>
        <div className={styles.imageLinks}>
          <Link>
            <img src={VisaSecureImage} alt="" />
          </Link>
          <Link>
            <img src={MastercardCheckImage} alt="" />
          </Link>
          <Link>
            <img src={MirAcceptImage} alt="" />
          </Link>
          <Link>
            <img src={MirImage} alt="" />
          </Link>
          <Link>
            <img src={MastercardImage} alt="" />
          </Link>
          <Link>
            <img src={VisaImage} alt="" />
          </Link>
        </div>
      </div>
      <FaqModal isOpen={faqModal} onClose={() => setFaqModal(false)} />
    </div>
  );
};

export default Footer;
