import styles from "../../components/Link/Link.module.scss";

const Link = (props) => {
  const { children, href, ...rest } = props;

  return (
    <a href={href} {...rest} className={styles.link}>
      {children}
    </a>
  );
};

export default Link;
